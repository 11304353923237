
export default {
  props:{
    region:{
      type:String,
      default:'uk',
    },
  },
  data(){
    return{
      recognised: this.region === 'uk' ? 'recognised' : 'recognized'
    }
  },
}
